import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Get in touch`}</h2>
    <p>{`If you're interested in working with me, send me an email with info about you, your organization, and what you're looking for from coaching.`}</p>
    <p><a parentName="p" {...{
        "href": "mailto:hi@ashu.xyz"
      }}>{`hi@ashu.xyz`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      